import React from "react";

import { Helmet } from "react-helmet";

import GalleryCard3 from "../components/gallery-card3";
import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Anclando la Nueva Humanidad</title>
      </Helmet>
      <div className="home-header">
        <header
          data-thq="thq-navbar"
          className="navbarContainer home-navbar-interactive"
        >
          <a href="/" class="logo-link">
            <img
              src="/external/logo2.png"
              alt="Delfina Galindez"
              class="site-logo"
            ></img>
          </a>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu"></div>
        </header>
      </div>
      <div className="home-hero">
        <div className="home-hero1 heroContainer">
          <div className="home-container01">
            <h1 className="home-hero-heading">Anclando la Nueva Humanidad</h1>
            <span className="home-hero-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <div className="home-btn-group">
              <button className="buttonFilled">
                <a href="/#intercambio-energetico">Unirme al evento</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <span className="home-text14">
              <span className="heading2">
                Sábado 23 de Marzo
                <br></br>
                09:00 México
                <br></br>
                11:00 Argentina
                <br></br>
                17:00 España
                <br></br>
                Duración 4hs
                <br></br>
                <br></br>
                Un día dedicado a la pausa y el crecimiento espiritual, donde
                elevarás tu energía, establecerás una conexión profunda con tu
                esencia, y te embarcarás en un viaje de autodescubrimiento y
                expansión. Es una oportunidad para abrir tus horizontes y unirte
                a otros en el camino hacia una humanidad renovada y llena de
                amor y libertad.
                <br></br>
                Nos unimos un grupo de seres de Argentina 🇦🇷, México 🇲🇽 y
                Paraguay 🇵🇾 con sabidurías y experiencias de vida muy distintas
                y nutritivas, dedicados al servicio y a la toma de conciencia.
                Estamos aquí para disfrutar, abrazar la vida y elegir
                soberanamente cómo vivirla. Unite a esta familia para llevar tu
                proceso acompañado, en tribu. Y que los impulsos del amor sean
                más fuertes que los miedos. ¡Vamos a trascender!
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="home-pricing" id="intercambio-energetico">
        <div className="home-pricing1 pricingContainer">
          <div className="home-container04">
            <h2 className="home-pricing-heading heading2">
              Intercambio energético
            </h2>
            <span className="home-pricing-sub-heading bodyLarge">
              <span></span>
            </span>
          </div>
          <div className="home-container05">
            <div className="basicPricingCard home-pricing-card">
              <div className="home-container06">
                <h2>Acceso al evento</h2>
              </div>
              <div className="home-container08">
                <div className="home-container09">
                  <span className="home-text24">✅</span>
                  <span className="bodySmall">
                    Conecta con una tribu consciente
                  </span>
                </div>
                <div className="home-container10">
                  <span className="home-text26">✅</span>
                  <span className="bodySmall">
                    Se parte de un espacio de sanación en conjunto
                  </span>
                </div>
                <div className="home-container11">
                  <span className="home-text27">✅</span>
                  <span className="bodySmall">
                    Exponte a nuevas perspectivas para tu evolución
                  </span>
                </div>
                <div className="home-container12">
                  <span className="home-text28">✅</span>
                  <span className="bodySmall">Eleva tu vibración</span>
                </div>
              </div>
              <h2 className="pago-en-linea">Pago en línea</h2>
              <span>
                Una vez realizado el pago, envía el comprobante{" "}
                <a
                  target="_blank"
                  className="texto-link"
                  href="https://wa.me/message/EUQFLETFV7NED1"
                >
                  aquí
                </a>{" "}
                o a{" "}
                <a
                  target="_blank"
                  className="texto-link"
                  href="mailto:tribunuevahumanidad@gmail.com?subject=Comprobante%20de%20Pago%20-%20Anclando%20la%20Nueva%20Humanidad"
                >
                  tribunuevahumanidad@gmail.com
                </a>{" "}
                para recibir el link de acceso al evento y a nuestra comunidad
                en Telegram.
              </span>
              <span>
                <h3>
                  Argentina: 
                  <br></br>
                </h3>
                <span>
                  <br></br>
                  <li className="list-item">
                    {" "}
                    Transferencia - <b>ARS 33.333</b>
                  </li>
                  <br></br>
                  Transfiere a cuenta/alias: <b>delfina.galindez.m</b>
                </span>
                <br></br>
                <br></br>
                <li className="list-item">
                  Paga con tarjeta - <b>ARS 35.555</b> 
                </li>
                <button id="payButton" class="pay-button">
                  <svg
                    class="credit-card-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <rect
                      x="1"
                      y="4"
                      width="22"
                      height="16"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="1" y1="10" x2="23" y2="10"></line>
                  </svg>
                  <a href="https://mpago.la/1vCawZC" target="_blank">
                    Pagar
                  </a>
                </button>
              </span>
              <span>
                <h3>México/Resto del mundo:</h3>
                <span>
                  <br></br>
                  <li className="list-item">
                    Paga con tarjeta - <b>USD 35 / MXN 589</b>
                  </li>{" "}
                  <button id="payButton" class="pay-button">
                    <svg
                      class="credit-card-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect
                        x="1"
                        y="4"
                        width="22"
                        height="16"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line>
                    </svg>
                    <a
                      href="https://pago.clip.mx/3680fa2a-059c-40fc-ab14-ac779f00b00e"
                      target="_blank"
                    >
                      Pagar
                    </a>
                  </button>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container13">
        <h1 className="home-text46 heading2">La familia que nos acompaña</h1>
        <div className="home-gallery">
          <div className="home-container14">
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-18%20at%2012.10.26-1500w.jpeg"
              rootClassName="rootClassName8"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-18%20at%2012.06.20-1500w.jpeg"
              rootClassName="rootClassName7"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-18%20at%2012.20.39-1500w.jpeg"
              rootClassName="rootClassName9"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-15%20at%2021.22.56-1500w.jpeg"
              rootClassName="rootClassName3"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-16%20at%2009.30.54-1500w.jpeg"
              rootClassName="rootClassName2"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-16%20at%2011.16.37-1500w.jpeg"
              rootClassName="rootClassName5"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/whatsapp%20image%202024-03-16%20at%2009.36.36-1500w.jpeg"
              rootClassName="rootClassName4"
            ></GalleryCard3>
            <GalleryCard3
              rootClassName="rootClassName6"
              imageSrc="/external/whatsapp%20image%202024-03-18%20at%2011.33.14-1500h.jpeg"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/bel.jpeg"
              rootClassName="rootClassName1"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/vic.jpeg"
              rootClassName="rootClassName"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/maru.jpeg"
              rootClassName="rootClassName10"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="/external/facu.jpeg"
              rootClassName="rootClassName11"
            ></GalleryCard3>
          </div>
          <div className="home-btn-group cta-bottom">
            <button className="buttonFilled">
              <a href="/#intercambio-energetico">Unirme al evento</a>
            </button>
          </div>
        </div>
      </div>
      <div className="home-banner">
        <div className="home-banner1 bannerContainer">
          <h2 className="home-banner-heading">Dudas o consultas</h2>
          <span className="home-banner-sub-heading bodyLarge">
            Para más información puedes escribirnos al whatsapp{" "}
            <a
              href="https://wa.me/message/EUQFLETFV7NED1"
              target="_blank"
              rel="noreferrer noopener"
              className="texto-link"
            >
              aquí
            </a>{" "}
            o enviarnos un correo a{" "}
            <a
              target="_blank"
              className="texto-link"
              href="mailto:tribunuevahumanidad@gmail.com?subject=Consulta%20-%20Anclando%20la%20Nueva%20Humanidad"
            >
              tribunuevahumanidad@gmail.com
            </a>
          </span>
        </div>
      </div>
      <div className="home-footer">
        <footer className="footerContainer home-footer1">
          <div className="home-container15">
            <a href="/" class="logo-link">
              <img
                src="/external/logo2.png"
                alt="Delfina Galindez"
                class="site-logo"
              ></img>
            </a>
          </div>
          <div className="home-separator"></div>
          <div className="home-container16">
            <span className="bodySmall home-text68">
              © 2024 Delfina Galindez. Todos los derechos reservados.
            </span>
            <div className="home-icon-group1">
              <a
                href="https://www.instagram.com/delfigalindez/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon10 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
